import React, { Component, lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'
import Loading from './Components/Loading/Loading'
const TermsAndConditions = lazy(() => import('./Pages/TermsAndConditions/TermsAndConditions'))
const HsmValidity = lazy(() => import('./Pages/HsmValidity/HsmValidity'))
const DocumentFinder = lazy(() => import('./Pages/DocumentFinder/DocumentFinder'))
// const About = lazy(() => import('./Pages/About/About'))

class App extends Component {
  render() {
    return (
      <div className="container-fluid" style={{ height: '100%' }}>
        <BrowserRouter>
          <div className="container-fluid" style={{ height: '100%' }}>
            <Header />
            <div className="container-fluid main-container-fix">
              <Switch>
                {/* <Route
                  exact
                  path="/sobre-nosotros"
                  render={props => (
                    <Suspense fallback={<Loading />}>
                      <About />
                    </Suspense>
                  )}
                /> */}
                <Route
                  exact
                  path="/validez-fea"
                  render={props => (
                    <Suspense fallback={<Loading />}>
                      <HsmValidity />
                    </Suspense>
                  )}
                />
                <Route
                  exact
                  path="/terminos-y-condiciones"
                  render={props => (
                    <Suspense fallback={<Loading />}>
                      <TermsAndConditions />
                    </Suspense>
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={props => (
                    <Suspense fallback={<Loading />}>
                      <DocumentFinder />
                    </Suspense>
                  )}
                />
                <Redirect to="/" />
              </Switch>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    )
  }
}

export default App
