import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(<App />, document.getElementById('root'))
