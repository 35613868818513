import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, NavbarBrand } from 'mdbreact'

export default class Header extends React.Component {
  render() {
    return (
      <Navbar
        color="default-color"
        dark
        expand="md"
        style={{ width: '100%', marginBottom: '10px' }}
      >
        <Link className="font-weight-bold" to="/">
          <NavbarBrand>
            <strong className="white-text">Red Notarial</strong>
          </NavbarBrand>
        </Link>
      </Navbar>
    )
  }
}
