import React from 'react'
import { Fa } from 'mdbreact'
import './style.css'

export default class Loading extends React.Component {
  render() {
    return (
      <div className="loading-container">
        <Fa icon="circle-o-notch" spin size="2x" />
      </div>
    )
  }
}
