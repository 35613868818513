import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row, Footer } from 'mdbreact'

export default class MainFooter extends React.Component {
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }

  render() {
    return (
      <Footer
        color="mdb-color"
        className="font-small pt-4 mt-4"
        style={{ bottom: 0, position: 'relative', width: '100%' }}
      >
        <Container className="text-center text-md-left">
          <Row className="text-center text-md-left mt-3 pb-3">
            <Col md="3" lg="3" xl="3" className="mx-auto mt-4">
              <a
                href="https://conservadoresdigitales.cl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h6 className="text-uppercase mb-4 font-weight-bold">Conservadores Digitales</h6>
              </a>
            </Col>
            <hr className="w-100 clearfix d-md-none" />
            <Col md="3" lg="3" xl="3" className="mx-auto mt-4">
              <Link to="/validez-fea" onClick={this.scrollToTop}>
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Validez de la Firma Electrónica Avanzada
                </h6>
              </Link>
            </Col>
            <hr className="w-100 clearfix d-md-none" />
            <Col md="3" lg="3" xl="3" className="mx-auto mt-4">
              <Link to="/terminos-y-condiciones" onClick={this.scrollToTop}>
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Términos y Condiciones de uso
                </h6>
              </Link>
            </Col>
            {/* <hr className="w-100 clearfix d-md-none" />
            <Col md="3" lg="3" xl="3" className="mx-auto mt-4">
              <Link to="/sobre-nosotros" onClick={this.scrollToTop}>
                <h6 className="text-uppercase mb-4 font-weight-bold">Sobre Nosotros</h6>
              </Link>
            </Col> */}
          </Row>
          <hr />
          <Row className="d-flex align-items-center">
            <Col md="8" lg="8" />
            <Col md="4" lg="4" className="ml-lg-0">
              <p className="text-center text-md-right grey-text">
                &copy; {new Date().getFullYear()} Powered By{' '}
                <a href="https://www.sodlab.com" target="_blank" rel="noopener noreferrer">
                  Sodlab
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Footer>
    )
  }
}
